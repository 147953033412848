.InputTemplate_addSymbol-with-title {
  position: absolute;
  left: 90%;
  top: 50%;
  transform: translateY(-50%);
  @include media-breakpoint-up(lg) {
    left: 80%;
  }
}

.InputTemplate_addSymbol {
  position: absolute;
  left: 85%;
  top: 50%;
  transform: translateY(-50%);
}

.ImageFileInput {
  height: 50px;
}

.OnePageTrackForm {
  position: absolute;
  right: 0 !important;
  left: 100 !important;
  // background-color: white;
  height: 100vh;
  max-width: 500px !important;
  margin-left: auto;
  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}

.OnePageTrackForm.modal {
  background-color: transparent;
  width: 500px;
  z-index: 105;

  max-width: 500px !important;
  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}
