.Overview__form_item:not(:last-child) {
  margin-right: 15px;
}

.ProfileShipmentCreateCard:hover > * {
  color: white;
}

.ProfileOverview__delete-image {
  position: absolute;
  top: 10px;
  left: 100px;
  width: 20px !important;
  height: 25px !important;
  display: none !important;
}

.ProfileOverview__passport-image:hover .ProfileOverview__delete-image {
  display: block !important;
}
