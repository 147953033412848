@import './custom/acceptTrackForm';

@import './custom/createDeliveryType';
@import './custom/createDirection';
@import './custom/createWebsite';

@import './custom/dashboard';
@import './custom/deleteConfirmation';
@import './custom/deliveryTypesItem';
@import './custom/deliveryTypeOne';
@import './custom/directions';

@import './custom/flagsDirectionBar';
@import './custom/formStyles';

@import './custom/itemStep';
@import './custom/itemSettings';

@import './custom/logoThumb';

@import './custom/mobileSettings';
@import './custom/modalNotification';

@import './custom/profilePage';

@import './custom/receivers';

@import './custom/settings';
@import './custom/statusBar';

@import './custom/trackPage';
@import './custom/tracksFilter';
@import './custom/translations';
@import './custom/translationEditModalForm';

@import './custom/userDirectionsCell';

//

.stepper-item.CustomStepper {
  display: flex;
  flex-direction: row;
}

*,
*::before,
*::after {
  // outline: 1px solid tomato;
}

.row-reverse {
  flex-direction: row-reverse;
}

.form-check-input {
  flex-shrink: 0;
}
.inactiveCheckbox {
  color: grey;
}

.activeCheckbox {
  color: black;
}
.flex-gutter > * {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

@include media-breakpoint-up(md) {
  .flex-md-gutter > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.flex-col-gutter > * {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.flex-col-rev-gutter > * {
  &:not(:first-child) {
    margin-bottom: 10px;
  }
}

.PaginationDropdown > button {
  background-color: grey !important;
  padding: 5px 10px !important;
  font-size: 0.8rem;
}

.bg-active {
  background-color: $activeBg;
}

.bg-blocked {
  background-color: $blockedBg;
}

.line-height-10px {
  line-height: 10px !important;
}

.balance-accordion-item.accordion-item {
  border: none;
  background-color: transparent;
}

.balance-accordion-header {
  & > .accordion-button {
    padding: 0;
  }
  & > .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
}

.show-chat.custom-chat-button {
  font-family: var(--bs-btn-font-family) !important;
  background-color: transparent !important;
  color: black !important;
  font-size: var(--bs-btn-font-size) !important;
  &::before {
    all: unset !important;
  }
  &::after {
    all: unset !important;
  }
  &:hover {
    font-size: var(--bs-btn-font-size) !important;
  }

  & > .unread_count {
    all: unset !important;
    display: none !important;
  }
}

@media (max-width: 767.99px) {
  // AuthLayout
  .bg-images-none {
    background-image: none !important;
  }

  .minify-button {
    padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width})
      calc(#{$btn-padding-x-sm} + #{$btn-border-width}) !important;
  }

  // stepper
  .stepper-nav:not(.active) .stepper-line {
    display: none !important;
  }
  .stepper-nav.active .stepper-line {
    display: block;
  }
  .stepper-item.CustomStepper:not(.current) {
    display: none !important;
  }
  .stepper-nav.active > .stepper-item.CustomStepper {
    display: block !important;
    transition: all 0.2s ease;
  }

  .stepper-item.CustomStepper.current .arrow {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    position: relative;
    margin: 0 1rem;

    span {
      top: 0.5rem;
      position: absolute;
      width: 0.75rem;
      height: 0.1rem;
      background-color: #000000;
      display: inline-block;
      transition: all 0.2s ease;

      &:first-of-type {
        left: 0;
        transform: rotate(45deg);
      }

      &:last-of-type {
        right: 0;
        transform: rotate(-45deg);
      }
    }

    &.active {
      span {
        &:first-of-type {
          transform: rotate(-45deg);
        }
        &:last-of-type {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.BasicTooltip {
  all: unset;
}

.payment-field {
  height: 46px !important;
}
.payment-field iframe {
  height: 100% !important;
}
.payment-field:has(.CollectJSValid) {
  border-color: $success !important;
}
.payment-field:has(.CollectJSInvalid) {
  border-color: $danger !important;
}

@media (min-width: 1400px) {
  .app-container {
    max-width: 1600px;
    justify-content: center;
  }
  .app-header {
    justify-content: right;
  }
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

// bootstrap breakpoints

// // def
//  @media (max-width: 575px) {
// }
// // sm
// @media (min-width: 576px) {
// }

// // md
// @media (min-width: 768px) {
// }
// // lg
// @media (min-width: 992px) {
// }

// // xl
// @media (min-width: 1200px) {
// }

// // xxl
// @media (min-width: 1400px) {
// }
